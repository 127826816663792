.subscribe-blog {
  background-color: #36b37e;
}
.oval-icon {
  background: url("../Images/Oval1.png");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}
.subscribe-blog-title {
  font-size: 35px;
  color: white;
  font-weight: 700;
}
.subscribe-details {
  font-size: 18px;
  color: white;
  font-weight: 600;
}
.input-subscribe {
  width: 100%;
}
::-webkit-input-placeholder {
  font-weight: 800px;
  color: #424242;
}
.subscribe {
  border-radius: 25px;
  background-color: #36b37e;

  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
}
@media (max-width: 400px) {
  .subscribe {
    padding: 0.5rem 1rem;
  }
}

.button-partx {
  display: flex;
}
