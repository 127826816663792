.bolder-font {
  font-size: 20px;
  font-weight: bolder;
}
.explore-btn {
  max-width: fit-content;
  border-radius: 10px;
  font-size: 24px;
}

.sec1 {
  background: url("../Assets/Images/img2_2.png") #f5f5f5;
  background-position: right top;
  background-repeat: no-repeat;
}

.sec1-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.grid-1fr {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.sec1-ul > li {
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 600;
}

.bg-white {
  background-color: white;
}

.bg-lightgreen {
  background-color: #f1fff9;
}

.section1-right-text {
  font-size: 20px;
  letter-spacing: 0.5px;
}

.last-row {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
}

.left-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.left-img img {
  border-radius: 100%;
  max-width: 100px !important;
  width: 100%;
}

.text-grn {
  color: #3cc474;
}

.bg-grn {
  background: #eafbf6 !important;
}

.sec2-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: white;
  padding: 50px 20px;
  border-radius: 3px;
}

.sec2-grid > .sec2-1st {
  border-right: 2px solid #3cc474;
  padding: 0px 30px;
}

.sec2-grid > .sec2-1st > img {
  width: 100%;
}

.sec2-grid > .sec2-2nd {
  border-left: 2px solid #3cc474;
  padding: 0px 30px;
}

.sec2-grid > .sec2-2nd > h2 {
  font-size: 30px;
  font-weight: bolder;
}

.sec2-grid > .sec2-2nd > p {
  font-size: 30px;
}

.sec2-grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  background-color: white;
  padding: 20px 0px;
  border-radius: 3px;
}

.sec2-grid2 .feature {
  padding: 0px 20px;
  border-left: 2px solid #3cc474;
  border-right: 2px solid #3cc474;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.sec2-grid2 .feature:first-child {
  border-left: none;
}

.sec2-grid2 .feature:last-child {
  border-right: none;
}

.sec3-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.sec3-grid .left-content {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding: 20px;
  background: white;
  border-radius: 3px;
}

.sec3-grid .sec3-1st {
  align-self: center;
}

.sec3-grid .right-img {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.sec4-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.sec4-grid .right-content-part {
  align-self: flex-end;
}

.content-part-heading {
  font-size: 3rem;
}

.header1 {
  font-size: 3rem;
}

.sec5-grid {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  grid-gap: 20px;
}

.left-content-part {
  align-self: center;
}

.perk {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #eafbf6;
  padding: 20px 0px;
  border-radius: 3px;
}

.perk .perk-txt {
  padding: 0px 20px;
  border-left: 2px solid #3cc474;
  border-right: 2px solid #3cc474;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.perk .perk-txt:first-child {
  border-left: none;
}

.perk .perk-txt:last-child {
  border-right: none;
}

.section5-main-img {
  max-height: 500px;
}

.content-tag {
  font-size: 1.5rem;
}

.right-img-part {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sec6-bg {
  background: url("../Assets/Images/img14.png") #d4faea;
  background-position: bottom left;
  object-fit: cover;
  background-repeat: no-repeat;
}

.sub-heading {
  font-size: 1.2rem;
}

.fw-normal {
  font-weight: 500px;
}

.sec6-flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.review-section {
  grid-gap: 10px;

  .card {
    border-radius: 25px;
  }
}

.sec7-fit-content {
  width: fit-content;
  margin: auto;
}

.mb-30px {
  margin-bottom: 30px;
}

.abs-position-right {
  position: absolute;
  right: 0;
  bottom: -25px;
  font-size: 20px;
}

.sec7-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    "el1 el2 el3 el4"
    "el1 el2 el3 el4"
    "el5 el2 el3 el8"
    "el5 el6 el7 el8"
    "el5 el6 el7 el9"
    "el5 el6 el7 el9";
  max-width: 1000px;
  margin: auto;
}

.sec7-grid > .el1 {
  grid-area: el1;
}

.bg-white {
  background-color: white;
}

.clr-white {
  color: white;
}

.white-circle {
  border-radius: 100%;
  border: 2px solid white;
  padding: 15px;
}

.el1-email-alerts {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
}

.el1-email-alerts .emailAlert-img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.clr-light-green {
  background-color: #90dbbb;
  border-radius: 100%;
  font-size: 10px;
  padding: 5px;
  color: white;
}

.sec7-grid > .el2 {
  grid-area: el2;
  background: white;
  position: relative;
}

.el-arrange-items {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 1rem;
  height: 100%;
}

.el-img-position {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 120px;
  padding-left: 1rem;
}

.el-img-position img.el-pt {
  padding-top: 4rem;
}

.abs-right {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.label {
  font-size: 1.3rem;
  font-weight: bold;
}

.sec7-grid > .el3 {
  grid-area: el3;
}

.sec7-grid > .el4 {
  grid-area: el4;
}

.sec7-grid > .el5 {
  grid-area: el5;
  background: #3cc474;
}

.el5-img-club {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 1rem;
  height: 100%;
}

.flex-left {
  align-self: flex-start;
}

.sec7-grid > .el6 {
  grid-area: el6;
}

.sec7-grid > .el7 {
  grid-area: el7;
}

.sec7-grid > .el8 {
  grid-area: el8;
}

.sec7-grid > .el9 {
  grid-area: el9;
}

.sec7-grid > div {
  min-height: 100px;
  align-self: stretch;
  /*background-color: blue;*/
  border-radius: 20px;
  position: relative;
}

.clr-grey {
  background-color: #71797e;
  border-radius: 100%;
  font-size: 10px;
  padding: 5px;
}

.sec7-grid > .el4 {
  grid-area: el4;
  background: #3cc474;
}
.sec7-grid > .el7 {
  grid-area: el7;
  background: #3cc474;
}
.sec7-grid > .el9 {
  grid-area: el9;
  background: #3cc474;
}

.sec7-grid > .el3 {
  grid-area: el3;
  background: white;
  position: relative;
}

.sec7-grid > .el6 {
  grid-area: el6;
  background: white;
  position: relative;
}
.sec7-grid > .el8 {
  grid-area: el8;
  background: white;
  position: relative;
}

.br-20px {
  border-radius: 20px;
}

.flex-top {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
}

.bg-grn-alt {
  background-color: #d4faea !important;
}

.sec-9-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.sec-9-grid > div {
  position: relative;
}

.sec9-card {
  padding: 30px 15px;
  border-radius: 20px;
  margin-top: 70px;
  position: relative;
  background-color: #f5f5f5;
}

.client-photo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.client-photo-scale {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) scale(1.4);
  z-index: 100;
}

.scale-1_3 {
  transform: scale(1.3);
}

.client-opinion {
  text-align: justify;
}

.logo-img {
  height: 80px;
  padding: 10px;
}

.sec10-header {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
  background: white;
  padding-bottom: 40px;
  margin-bottom: -20px;
}

.sec10-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.empty-green-box {
  background-color: #3cc474;
  height: 200px;
  border-radius: 20px 20px 0px 0px;
  position: relative;
}

.img-depict {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 101px;
}

.img-depict img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.sec10-card-alt {
  background-color: #f5f5f5;
  border-radius: 20px;
  height: 400px;
  z-index: 100;
  padding: 20px;
  padding-top: 100px;
  margin-top: -30px;
}

.sec10-card {
  display: grid;
  position: relative;
}

.sec10-img-lg {
  top: 40px;
  border-radius: 100%;
  padding: 20px;
  height: 200px;
  width: 200px;
  background-color: white;
}

.sec10-img-md {
  top: 70px;
  border-radius: 100%;
  padding: 20px;
  height: 170px;
  width: 170px;
  background-color: white;
}

.sec10-img-sm {
  top: 100px;
  border-radius: 100%;
  padding: 20px;
  height: 140px;
  width: 140px;
  background-color: white;
}

.sec10-point {
  display: flex;
  align-items: center;
  padding: 10px;
}

.sec10-point p {
  margin: 0;
}

.sec10-card-alt hr {
  height: 3px;
  margin: 20px -20px;
}

.benefit-title {
  text-align: center;
}

.sec12-img img {
  height: 500px;
}

.section11-layout {
  padding: 2rem;
}

.sec11-grid {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: center;
  justify-content: center;
}

.side-heading {
  max-width: 400px;
  margin: auto;
}

.answer-qestion {
  border-radius: 10px;
  background-color: white;
}
.question {
  font-size: 20px;
  font-weight: 700;
  color: #183b56;
}
.accordion-title {
  font-size: 32px;
  font-weight: 700;
}
.accordion {
  background-color: #f3faf7;
}
.fa-plus,
.fa-minus {
  color: #36b37e;
}
.question-flex {
  cursor: pointer;
}
.ans {
  font-size: 16px;
  font-weight: 500;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.right-btn-footer {
  padding: 12px 22px;
  width: 13rem;
  border-radius: 30px;
  border: none;
  outline: none;
  background-color: #ffffff;
  color: #2caf82;
  font-size: 18px;
  font-weight: 700;
}
ul {
  list-style: none;
  padding-left: 0rem;
}
.footer-alt {
  background-color: #ffffff;
  /* padding: 30px 0; */
  /* position: relative; */
  /* margin-top: 20rem; */
}
.retalive-alt {
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translate(-50%, -20%);
}
.position-footer {
  background-color: #36b37e;
  border-radius: 10px;
  background: url("../Images/Oval1.png");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}
.footer-grid2-alt1 {
  display: grid !important;
  grid-template-columns: 1.2fr 1fr 1fr 1fr 1.2fr;
}

.footer-padding2-alt {
  padding-top: 6rem;
}

.position-footer-title1 {
  font-size: 31px;
  color: white;
  font-weight: 700;
}
.address {
  font-size: 18px;
  color: white;
  font-weight: 700;
}
.position-footer-deatils {
  font-size: 18px;
  font-weight: 600;
  color: white;
}
.footer-col h5 {
  font-size: 18px;
  color: #0c0c0c;
  text-transform: capitalize;
  margin-bottom: 25px;
  text-align: start;
  font-weight: 500;
  position: relative;
}
.footer-col h6 {
  color: #bbbbbb;
  font-size: 15px;
  font-weight: 600;
}
.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a,
.last-deatils {
  font-size: 14px;
  text-transform: capitalize;

  text-decoration: none;
  font-weight: 600;
  color: #474747;
  display: block;
  transition: all 0.3s ease;
  text-align: start;
}
.footer-col ul li a:hover {
  color: #ffffff;
}

.footer-col ul {
  margin-top: 30px;
}

.copyright-part {
  font-weight: 700;
}
.logo-bottom-text {
  font-weight: 700;
}
.icon-footer-grid {
  display: flex;
  justify-content: space-between;
}
/*responsive*/
@media (max-width: 1057px) {
  .footer-col ul {
    margin-top: 0px;
  }

  .footer-grid2-alt1 {
    grid-template-columns: 1fr 1fr;
  }

  .footer-col h5 {
    margin-bottom: 10px;
  }
}
@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}
@media (max-width: 370px) {
  .right-btn-footer {
    width: 90%;
    font-size: 16px;
  }
}

.sec6-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  align-items: space-around;
}

.sec6-grid img {
  max-width: 100%;
}

img {
  max-width: 100% !important;
}

.w-80 {
  width: 80% !important;
}

.fs-0 {
  font-size: 3.5rem !important;
}

.fs-0_5 {
  font-size: 3rem !important;
}

.scale1_1 {
  transform: scale(1.05);
}

.scale1_2 {
  transform: scale(1.2);
}

.pt-50px {
  padding-top: 50px;
}

@media (max-width: 1000px) {
  .mt-6rem {
    margin-top: 1.5rem !important;
  }

  .spy-1 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  *.w-80,
  h2.w-80,
  p.w-80,
  button.w-80,
  p.w-80 {
    width: 100% !important;
  }

  .justify-self-end {
    justify-self: center !important;
  }

  .el5-img-club {
    align-items: flex-start;
  }
  .el5-img-club .img-club {
    padding-left: 1rem;
  }

  .sec6-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .sec7-grid {
    grid-template-areas:
      "el1" "el2"
      "el3" "el4"
      "el5" "el6"
      "el7" "el8"
      "el9" "el9";
  }
  .pt-001 {
    padding-top: 5rem !important;
  }

  .sec2-grid {
    grid-template-columns: 1fr;
    padding: 0px 30px;
  }

  .sec2-grid > .sec2-1st {
    border-bottom: 2px solid #3cc474;
    border-right: none;
    padding: 20px 10px;
  }

  .sec2-grid > .sec2-1st > img {
    width: 100%;
  }

  .sec2-grid > .sec2-2nd {
    border-top: 2px solid #3cc474;
    border-left: none;
    padding: 20px 10px;
  }

  .sec2-grid > .sec2-2nd > h2 {
    font-size: 30px;
    font-weight: bolder;
  }

  .sec2-grid > .sec2-2nd > p {
    font-size: 30px;
  }

  .sec2-grid2 {
    grid-template-columns: 1fr;
  }

  .sec2-grid2 .feature {
    padding: 20px 0px;
    border-left: none;
    border-right: none;
    border-top: 2px solid #3cc474;
    border-bottom: 2px solid #3cc474;
  }

  .sec2-grid2 .feature:first-child {
    border-top: none;
  }

  .sec2-grid2 .feature:last-child {
    border-bottom: none;
  }

  .sec3-grid,
  .sec4-grid,
  .sec5-grid,
  .sec-9-grid,
  .sec10-grid,
  .sec11-grid,
  .sec1-grid {
    grid-template-columns: 1fr;
  }

  .button {
    white-space: break-spaces;
  }

  .sec10-header {
    flex-direction: column;
  }

  .sec10-header > .header1 {
    margin-top: 20px;
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  .subscriber-wrapper {
    padding: 10px;
  }

  *.fs-0,
  .content-part-heading {
    font-size: 2.5rem !important;
  }

  *.fs-0_5 {
    font-size: 1.8rem !important;
  }

  .sec6-grid {
    grid-template-columns: 1fr 1fr;
  }

  .last-row {
    grid-template-columns: 1fr;
  }

  .last-row img {
    border: 100%;
    width: auto;
  }

  .perk {
    grid-template-columns: 1fr;
    padding: 20px 10px;
  }

  .perk .perk-txt {
    padding: 20px 10px;
    border-left: none;
    border-right: none;
    border-top: 2px solid #3cc474;
    border-bottom: 2px solid #3cc474;
  }

  .perk .perk-txt:first-child {
    border-top: none;
  }

  .perk .perk-txt:last-child {
    border-bottom: none;
  }

  .section11-layout {
    padding: 0;
  }
}

.justify-self-end {
  justify-self: flex-end;
}

.spy-1 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.ps-1_5 {
  padding-left: 1.5rem;
}

.mt-6rem {
  margin-top: 5rem;
}

.no-border-radius {
  border-radius: 2px;
}

.max-width-600px {
  max-width: 600px;
}

.btn-clr {
  background: #36b37e;
}

.btn-clr.disabled,
.btn-clr:disabled {
  background-color: rgba(54, 179, 126, 0.8);
}

.input-group {
  border: none;
  *:focus {
    box-shadow: none;
  }
}
