.header {
  background-image: linear-gradient(
      to bottom,
      rgba(67, 71, 70, 0.52),
      rgba(38, 194, 90, 0.199)
    ),
    url("../Images/pic1.png");
  height: 97vh;
  background-size: cover;
  background-repeat: no-repeat;
}
.from-box {
  background-color: #f8f8f8;
  border-radius: 14px;
}
.from-text {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;

  /* identical to box height, or 146% */

  text-align: center;

  /* Heading */

  color: #212b27;
}
.home-input {
  background-color: white;
  border-radius: 25px !important;
  padding: 10px 18px;
  border: none;
}
::-webkit-input-placeholder {
  font-size: 14px;
  color: #97979763;
  font-weight: 500;
}
.right-home-title {
  font-size: 30px;
  font-weight: 600;
  color: white;
}
.landing-page-deatils {
  font-size: 18px;
  font-weight: 600;
  color: #2caf82;
}
.landing-tick-text {
  font-size: 16px;
  font-size: 600;
  color: white;
}
.new-data {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  align-content: center;
  align-items: center;
}
.line {
  height: 2px;
  background-color: #ffffff;
}
.new-data-text {
  font-weight: 700;
  font-size: 18px;
  color: white;
}
.icon-part-home {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.icon-box-home {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #2caf82;
  position: relative;
  cursor: pointer;
}
.home-brands-icons {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home-center {
  position: absolute;
  top: 61%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.responsive-grid {
  display: grid;
  grid-template-columns: 1fr;
}
@media (max-width: 990px) {
  .header {
    height: 120vh;
  }
  .responsive-grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
@media (max-width: 800px) {
  .home-center {
    position: absolute;
    top: 71%;
    left: 50%;
  }
  .header {
    height: 130vh;
  }
  .responsive-grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
@media (max-width: 500px) {
  .home-center {
    position: absolute;
    top: 71%;
    left: 50%;
  }
  .header {
    height: 140vh;
  }
  .responsive-grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
@media (max-width: 500px) {
  .home-center {
    position: absolute;
    top: 80%;
    left: 50%;
  }
  .header {
    height: 150vh;
  }
  .responsive-grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
@media (max-width: 350px) {
  .home-center {
    position: absolute;
    top: 90%;
    left: 50%;
  }
  .header {
    height: 180vh;
  }
  .responsive-grid {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
