.pricing-title {
	font-size: 26px;
	font-weight: 700;
}
.pricing {
	background-color: #f5f5f5;
}
.pricing-deatails {
	color: #2caf82;
	font-weight: 600;
	font-size: 18px;
}
.price span {
	font-size: 30px;
	font-weight: 700;
	margin-right: 10px;
}
.price-deatils {
	font-size: 20px;
	font-weight: 700;
}
.pricing-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
}
.color {
	color: #2caf82;
}
.btn-withoutselect .fa-arrow-right {
	color: rgb(37, 34, 34);
}
.btn-withselect .fa-arrow-right {
	color: rgb(255, 255, 255);
}
.btn-withoutselect {
	background-color: transparent;
	padding: 10px 18px;
	font-weight: 500;
	border: 1px solid #5a7184;
	outline: none;
	border-radius: 25px;
}
.pricing-grid-item {
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	justify-content: space-between;
}
.btn-withselect {
	background-color: #2caf82;
	padding: 10px 18px;
	color: white;
	outline: none;
	border: none;
	font-weight: 500;
	border: 1px solid none;
	border-radius: 25px;
}
@media (max-width: 1000px) {
	.pricing-grid {
		grid-template-columns: 1fr 1fr;
	}
}
@media (max-width: 574px) {
	.pricing-grid {
		grid-template-columns: 1fr;
	}
}

.pricing-header-title {
	font-size: 35px;
	font-weight: 700;
}
.pricing-header-title span {
	color: #36b37e;
}
.form-check .form-check-input {
	float: none;
	font-size: 18px;
}
.form-check .form-check-input:focus {
	box-shadow: none;
}
.form-check-input:checked {
	background-color: #36b37e;
	border-color: #36b37e;
	background-position: left center;
}
.package-title {
	color: #36b37e;
	font-weight: 700;
	font-size: 35px;
}

.border-green {
	border: 1px solid #36b37e;
}
.monthly-color {
	color: gray;
	font-weight: 700;
}
.monthly {
	color: black;
	font-weight: 700;
}
.price {
	font-weight: 700;
}
