.latest-blog {
	/* background-color: #faf9f9; */
	background-color: #fff;
}
.leadership {
	background-color: white;
}
.grid-box {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.latest-blog-title {
	font-size: 34px;
	color: #36b37e;
	font-weight: 700;
}
.latest-blog-deatils {
	font-weight: 700;
}
@media (max-width: 550px) {
	.grid-box {
		grid-template-columns: 1fr;
		text-align: center;
	}

	.grid-box .justify-content-between{
		justify-content: center !important;
	}

	.grid-box .align-items-center{
		justify-content: center !important;
	}
}
