.subscriber-wrapper {
  background: #3cc474;
  min-height: 350px;
  padding: 30px;
  color: white;
}

/* .bg-black > span {
  background: #000;
  color: white;
} */

.fs-8 {
  font-size: 0.9rem;
}

.color-white {
  color: white;
}
