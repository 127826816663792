.home {
}
.header-text {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 130%;
  text-align: center;
  color: #000000;
}
.header-subtitle {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
}

.highlight-text {
  color: #2caf82;
}

.left-btn {
  padding: 16px 22px;
  width: 13rem;
  border-radius: 30px;
  border: none;
  outline: none;
  background-color: #2caf82;
  color: white;
  font-size: 18px;
  font-weight: 700;
}
.right-btn {
  padding: 16px 22px;
  width: 13rem;
  border-radius: 30px;
  border: none;
  outline: none;
  background-color: #ffffff;
  color: #2caf82;
  font-size: 18px;
  font-weight: 700;
}
.header-home-video {
  max-width: 800px;
  background-position: center;
  background-size: cover;
  position: relative;
  margin: auto;
  text-align: center;
}

.header-video-thumbnail {
  width: 100%;
  max-width: 700px;
  box-shadow: 2px 2px 21px 0px rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  filter: blur(0.25px);
}

.play-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.play-circle {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  box-shadow: 3px 4px 35px 10px rgb(44, 175, 130, 0.7);
}
.fa-play {
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  color: #36b37e;
}
.design-export {
  background-color: #2caf82;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}
.design-export-title {
  font-size: 25px;
  color: white;
  font-weight: 700;
}
.design-export-details {
  color: white;
  font-weight: 500;
  font-size: 18px;
}
/*responsive*/
@media (max-width: 500px) {
  .left-btn,
  .right-btn {
    width: 90%;
  }
}

.singleImage .carousel-indicators,
.singleImage .carousel-control-prev,
.singleImage .carousel-control-next {
  display: none;
}

.image-slider .modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
}
.image-slider .modal-content .carousel-modal.modal-body {
  max-height: 60vh !important;
  width: 100%;
  padding: 0px !important;
}
.image-slider .modal-content .carousel-modal.modal-body .carousel.slide {
  max-height: 40vw !important;
}
.image-slider
  .modal-content
  .carousel-modal.modal-body
  .carousel.slide
  .carousel-inner {
  max-height: 60vh !important;
}
.image-slider
  .modal-content
  .carousel-modal.modal-body
  .carousel.slide
  .carousel-inner
  .carousel-item {
  max-height: 60vh !important;
  margin: auto;
}
.image-slider
  .modal-content
  .carousel-modal.modal-body
  .carousel.slide
  .carousel-inner
  .carousel-item
  img {
  max-height: 60vh !important;
  object-fit: cover;
  margin: auto;
}
.image-slider
  .modal-content
  .carousel-modal.modal-body
  .carousel.slide
  .carousel-inner
  .carousel-item
  video {
  object-fit: cover;
  width: inherit;
}
.image-slider
  .modal-content
  .carousel-modal.modal-body
  .carousel.slide
  .carousel-control-next {
  height: 50%;
  margin: auto;
}
.image-slider
  .modal-content
  .carousel-modal.modal-body
  .carousel.slide
  .carousel-control-prev {
  height: 50%;
  margin: auto;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.user-img {
  height: 80px;
}

.blogs-box {
  background-color: #ffffff;
  padding: 30px 0;
  position: relative;
  margin-top: 200px;
}

.user-img {
  border-radius: 50%;
}

.header-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 30px;
}

.header-grid-alt {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 30px;
}

.mw-opt {
  max-width: 40vw;
  margin: auto;
}

.bg-greenish {
  background-color: #ecf4f1;
}

.bg-grayish {
  background-color: #f5f5f5;
}

.btn-alt {
  padding: 16px 22px;
  border-radius: 30px;
  border: none;
  outline: none;
  background-color: #2caf82;
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.header-text1 {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 130%;
  text-align: center;
  color: #000000;
}

.align-end {
  align-self: end;
}

.align-start {
  align-self: start;
}

.bs-sm {
  box-shadow: 0px -1px 4px rgba(54, 179, 126, 0.3);
}

@media screen and (max-width: 990px) {
  .header-grid {
    grid-template-columns: 1fr;
    align-items: center !important;
    justify-items: center !important;
    grid-gap: 0px;
  }
  .header-grid > * {
    align-self: center !important;
    justify-self: center !important;
  }

  .header-grid-alt {
    grid-template-columns: 1fr;
    align-items: center !important;
    justify-items: center !important;
    grid-gap: 0px;
  }

  .header-grid-alt > * {
    align-self: center !important;
    justify-self: center !important;
  }

  .reverse-grid > div:nth-child(2) {
    grid-row-start: 1;
  }

  .header-grid .header-text {
    font-size: 25px;
  }

  .header-grid .header-text1 {
    font-size: 20px;
  }

  .header-grid .header-subtitle {
    font-size: 16px;
  }

  .header-grid .mb-6rem {
    margin: 0;
  }

  .mw-opt {
    width: 100%;
    max-width: 400px;
    margin: auto;
  }

  .r-m-0 {
    margin: 0 !important;
  }

  .t-j {
    text-align: justify !important;
  }
}
