.withoutScroll {
	background-color: white !important;
}
.scroll {
	background-color: #ffffff;
}
.button {
	border-radius: 25px;
	background: #36b37e;
	border: none;
	outline: none;
	color: white;
	font-weight: 500;
	padding: 0.5rem 1.5rem;
}
.button:hover {
	background-color: #0ca383;
	color: white;
}
/* Remove border from toggler */
.navbar-toggler {
	border: 0;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
	outline: none;
	box-shadow: none;
	border: 0;
}

/* Lines of the Toggler */
.toggler-icon {
	width: 30px;
	height: 3px;
	background-color: #494241;

	display: block;
	transition: all 0.2s;
}

/* Adds Space between the lines */
.middle-bar {
	margin: 5px auto;
}

/* State when navbar is opened (START) */
.navbar-toggler .top-bar {
	transform: rotate(45deg);
	transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
	opacity: 0;
	filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
	transform: rotate(-45deg);
	transform-origin: 10% 90%;
}
/* State when navbar is opened (END) */

/* State when navbar is collapsed (START) */
.navbar-toggler.collapsed .top-bar {
	transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
	opacity: 1;
	filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
	transform: rotate(0);
}
/* State when navbar is collapsed (END) */

/* Color of Toggler when collapsed */
.navbar-toggler.collapsed .toggler-icon {
	background-color: #777777;
}

.icon-box {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	position: relative;
	background-color: white;
	cursor: pointer;
	transition: 0.2s;
}
.icon-box:hover {
	background-color: #282e79;
	color: white;
}
/* .logo {
	width: 70%;
} */
/* .fa-discord,
.fa-twitter,
.fa-instagram {
	font-size: 23px;
	position: absolute;
	cursor: pointer;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
} */
.nav-link {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 144.69%;
	/* identical to box height, or 26px */

	color: #000000;
}
.a-link {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 144.69%;
	/* identical to box height, or 26px */

	color: #000000;
}
.a-link1 {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 144.69%;
	/* identical to box height, or 26px */

	color: #ffffff;
}
a:hover {
	color: #08ca79 !important;
}
a:active {
	color: #36b37e;
}
@media (max-width: 990px) {
	/* .logo {
		width: 400px;
	} */
}
@media (max-width: 700px) {
	/* .logo {
		width: 200px;
	} */
}
@media (max-width: 340px) {
	/* .logo {
		width: 150px;
	} */
}
