@media (max-width: 1000px) {
  .grid-blog-1_5-1fr {
    grid-template-columns: 1fr !important;
  }

  .blogs-items {
    position: relative !important;
    top: 0px !important;
  }

  .blogs-items-wrapper {
    margin-bottom: 10px;
  }

  .blogs-items-wrapper .blog-img img {
    border-radius: 3px;
    max-width: 100%;
    object-fit: cover;
    max-height: 165px;
  }

  .split-grid {
    display: grid;
    grid-template-columns: 1fr !important;
    grid-gap: 30px;
  }
}

.split-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.blogs-items-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 30px;
  margin-right: 30px;
}

.blogs-items-right .blog-img1 {
  align-self: flex-end;
}

.blog-img1 img {
  border-radius: 3px;
  height: 100%;
  max-width: 90vw;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .blog-img1 img {
    border-radius: 3px;
    height: 100%;
    max-width: 90vw;
    object-fit: cover;
  }
}

.blog-img1 {
  width: 100%;
  justify-self: flex-end;
}

.dull {
  opacity: 0.6;
}

.bottom-deatils1 {
  font-size: 14px;
  font-weight: 100;
}

.bottom-box {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-box .inner-box {
  width: 80%;
  max-width: 450px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
}

.pl-15 {
  padding-left: 15px;
}
