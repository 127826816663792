.slider-part {
	background-color: white;
}
.slider-book {
	font-size: 28px;
	font-weight: 700;
}
.quote-name {
	font-size: 14px;
	font-weight: 700;
	color: #2caf82;
}
.quote-deatails {
	font-weight: 700;
}
