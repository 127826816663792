.blog-page-title {
	font-size: 35px;
	font-weight: 800;
}
.blog-page-title span {
	color: #36b37e;
}
.blog-page {
	background-color: #f5f5f5;
}
.input-btn-width {
	background-color: white;
	border-radius: 25px;
}
.header-blog {
	width: 100%;
	background: url("../Images/blogbg.png");
}
.input-subscribe {
	outline: none;
	padding: 8px 18px;
	border: none;
}
@media (max-width: 500px) {
	.input-subscribe {
		width: 70%;
	}
	::-webkit-input-placeholder {
		font-size: 12px;
	}
	.button {
		padding: 0.5rem 1rem;
	}
}
