.row {
  display: flex;
  flex-wrap: wrap;
}
.right-btn-footer {
  padding: 12px 22px;
  width: 13rem;
  border-radius: 30px;
  border: none;
  outline: none;
  background-color: #ffffff;
  color: #2caf82;
  font-size: 18px;
  font-weight: 700;
}
ul {
  list-style: none;
  padding-left: 0rem;
}
.footer-alt {
  background-color: #ffffff;
  /* padding: 30px 0; */
  /* position: relative; */
  /* margin-top: 20rem; */
}
.retalive-alt {
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translate(-50%, -20%);
}
.position-footer {
  background-color: #36b37e;
  border-radius: 10px;
  background: url("../../Images/Oval1.png");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}
.footer-grid2-alt {
  display: grid !important;
  grid-template-columns: 1.2fr 1fr 1fr 1fr 1fr 1.2fr;
}

.footer-padding2-alt {
  padding-top: 6rem;
}

.position-footer-title1 {
  font-size: 31px;
  color: white;
  font-weight: 700;
}
.address {
  font-size: 18px;
  color: white;
  font-weight: 700;
}
.position-footer-deatils {
  font-size: 18px;
  font-weight: 600;
  color: white;
}
.footer-col h5 {
  font-size: 18px;
  color: #0c0c0c;
  text-transform: capitalize;
  margin-bottom: 25px;
  text-align: start;
  font-weight: 500;
  position: relative;
}
.footer-col h6 {
  color: #bbbbbb;
  font-size: 15px;
  font-weight: 600;
}
.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a,
.last-deatils {
  font-size: 14px;
  text-transform: capitalize;

  text-decoration: none;
  font-weight: 600;
  color: #474747;
  display: block;
  transition: all 0.3s ease;
  text-align: start;
}
.footer-col ul li a:hover {
  color: #ffffff;
}
.copyright-part {
  font-weight: 700;
}
.logo-bottom-text {
  font-weight: 700;
}
.icon-footer-grid {
  display: flex;
  justify-content: space-between;
}
/*responsive*/
@media (max-width: 1057px) {
  .footer-grid2-alt {
    grid-template-columns: 1fr 1fr;
  }

  .footer-col h5 {
    margin-bottom: 10px;
  }
}
@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}
@media (max-width: 370px) {
  .right-btn-footer {
    width: 90%;
    font-size: 16px;
  }
}
