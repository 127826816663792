@import "~react-toastify/dist/ReactToastify.css";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Raleway:wght@300;400;500;600;700;800&display=swap";
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css";
@import "https://pro.fontawesome.com/releases/v5.10.0/css/all.css";
@import "https://unpkg.com/aos@2.3.1/dist/aos.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 7px;
  background-color: #999;
}

::selection {
  background-color: rgba(76, 175, 80, 0.6);
  color: white;
}

.facebook,
.twitter,
.linkedin,
.instagram,
.mail {
  color: black;
  cursor: pointer;
  font-size: 25px;
  position: static;
  transform: translate(0, 0);
}

.twitter {
  width: 25px;
}

.facebook:hover {
  color: #4267b2;
}

.linkedin:hover {
  color: #0e76a8;
}

.twitter:hover {
  fill: #00acee;
}
.instagram:hover path {
  fill: url(#Instagram);
}

.mail:hover {
  color: #0072c6;
}

.footer-logo {
  height: 45px;
}

#loading-modal {
  display: none;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
}

#loading-modal .svgContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#error-modal {
  display: none;
}

#error-modal .modal-body p {
  margin: 0;
  padding: 20px 10px;
}

#error-modal.modal-header,
#error-modal.modal-footer {
  padding: 10px;
}

body.loading #loading-modal,
body.loading-all #loading-modal {
  display: block;
}

body.loading #loading-modal .svgContainer {
  display: block !important;
}

@keyframes bg {
  0% {
    background: linear-gradient(
      to right bottom,
      rgba(54, 88, 80, 0.75),
      rgba(29, 196, 233, 0.75)
    );
  }
  50% {
    background: linear-gradient(
      to right bottom,
      rgba(29, 196, 233, 0.75),
      rgba(54, 88, 80, 0.75),
      rgba(29, 196, 233, 0.75)
    );
  }
  100% {
    background: linear-gradient(
      to right bottom,
      rgba(54, 88, 80, 0.75),
      rgba(29, 196, 233, 0.75)
    );
  }
}

#redirecting-modal {
  display: none;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation: bg 2s infinite;
  background: linear-gradient(
    to right bottom,
    rgba(29, 196, 233, 0.75),
    rgba(54, 88, 80, 0.75),
    rgba(29, 196, 233, 0.75)
  );
}

body.redirect #redirecting-modal {
  display: block;
}

.please-wait {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: white;
}

body.body-error {
  overflow: hidden;
}

body.body-error .modal,
body.loading .modal,
body.loading-all .modal {
  background: rgba(0, 0, 0, 0.5);
}

body.body-error #error-modal {
  display: block !important;
}

#error-modal {
  z-index: 1000000;
}

.inline-block {
  display: inline-block;
}

.pl-2 {
  padding-left: 10px;
}

.nav-link:focus {
  color: currentColor;
}

.greet {
  font-size: 24px;
}

.text-bold {
  font-weight: 500px;
}

.h-0 {
  height: 0;
}

.text-justify {
  text-align: justify;
}

.nav-link.a-link.active {
  color: #08ca79 !important;
  border-bottom: 3px solid #08ca79;
  border-radius: 25%;
}

.facebook-bg:hover {
  background-color: #4267b2;
}

.linkedin-bg:hover {
  background-color: #0e76a8;
}

.twitter-bg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.twitter-bg svg {
  fill: white;
  width: 20px;
}

.twitter-bg:hover {
  background-color: #00acee;
}
.instagram-bg:hover {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}

.mail-bg:hover {
  background-color: #0072c6;
}

.a-no-style,
.a-no-style:link,
.a-no-style:focus,
.a-no-style:visited,
.a-no-style:active {
  color: currentColor;
  text-decoration: none;
}

.modal .close {
  background: transparent;
  border: none;
  font-size: 20px;
  padding: 0;
}

.blog-img img {
  border-radius: 3px;
  height: 100%;
  max-width: 90vw;
  object-fit: cover;
  max-height: 165px;
}

@media screen and (max-width: 1024px) {
  .blog-img img {
    border-radius: 3px;
    height: 100%;
    max-width: 90vw;
    object-fit: cover;
    max-height: 165px;
  }
}

.blog-img {
  width: 100%;
  justify-self: center;
}

.read-more-btn,
.read-more-btn:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.mb-6rem {
  margin-bottom: 6rem;
}

.modal-actions {
  position: absolute;
  right: 6px;
  z-index: 10000;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.blogger-img {
  height: 40px;
  border-radius: 50%;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #36b37e;
  border-color: #36b37e;
  box-shadow: none;
}

#faq ul li {
  text-indent: 20px;
}

@media (max-width: 450px) {
  .wd-450-block {
    display: block !important;
  }

  .wd-450-none {
    display: none !important;
  }

  .slick-dots {
    visibility: hidden !important;
  }
}

.subscribe-alt {
  background-color: white !important;
  color: #36b37e !important;
}

.parent-100vh {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.h-0 {
  height: 0px !important;
  opacity: 0;
}

/* privacy policy */
.ion-header {
  padding: 20px 0px;
  box-shadow: 2px 4px 10px #bbb;
  margin-bottom: 10px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: white;
}
.ion-content {
  margin-top: 80px;
}
.tc_content {
  padding: 10px;
}
.tc_section {
  margin-bottom: 20px;
}
.tc_section_title {
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 600;
}
.tc_block {
  margin-bottom: 5px;
}
.tc_subtitle {
  margin-bottom: 7px;
  font-size: 18px;
  font-weight: 600;
}
.tc_para {
  font-size: 16px;
}
.tc_bold {
  font-weight: 600;
}
.tc_points > li {
  list-style-type: none;
  font-size: 16px;
  line-height: 1.3;
}
.tc_cp {
  cursor: pointer;
}
::marker {
  display: none;
}
.ion-content {
  height: calc(100vh - 80px);
  padding-left: 10px !important;
}
.ion-title {
  margin-left: 20px;
  font-size: 25px;
}
.mt-3 {
  margin-top: 10px;
}

.abs-right {
  position: absolute;
  right: 10px;
  top: 10px;
}

.machine {
  width: 60vmin;
  fill: #8bc53f;
}
.machine .black-fill {
  fill: #010101;
}
.machine .small-shadow,
.machine .medium-shadow,
.machine .large-shadow {
  fill: rgba(0, 0, 0, 0.05);
}
.machine .small {
  -webkit-animation: counter-rotation 2.5s infinite linear;
  -moz-animation: counter-rotation 2.5s infinite linear;
  -o-animation: counter-rotation 2.5s infinite linear;
  animation: counter-rotation 2.5s infinite linear;
  -webkit-transform-origin: 100.136px 225.345px;
  -ms-transform-origin: 100.136px 225.345px;
  transform-origin: 100.136px 225.345px;
}
.machine .small-shadow {
  -webkit-animation: counter-rotation 2.5s infinite linear;
  -moz-animation: counter-rotation 2.5s infinite linear;
  -o-animation: counter-rotation 2.5s infinite linear;
  animation: counter-rotation 2.5s infinite linear;
  -webkit-transform-origin: 110.136px 235.345px;
  -ms-transform-origin: 110.136px 235.345px;
  transform-origin: 110.136px 235.345px;
}
.machine .medium {
  -webkit-animation: rotation 3.75s infinite linear;
  -moz-animation: rotation 3.75s infinite linear;
  -o-animation: rotation 3.75s infinite linear;
  animation: rotation 3.75s infinite linear;
  -webkit-transform-origin: 254.675px 379.447px;
  -ms-transform-origin: 254.675px 379.447px;
  transform-origin: 254.675px 379.447px;
}
.machine .medium-shadow {
  -webkit-animation: rotation 3.75s infinite linear;
  -moz-animation: rotation 3.75s infinite linear;
  -o-animation: rotation 3.75s infinite linear;
  animation: rotation 3.75s infinite linear;
  -webkit-transform-origin: 264.675px 389.447px;
  -ms-transform-origin: 264.675px 389.447px;
  transform-origin: 264.675px 389.447px;
}
.machine .large {
  -webkit-animation: counter-rotation 5s infinite linear;
  -moz-animation: counter-rotation 5s infinite linear;
  -o-animation: counter-rotation 5s infinite linear;
  animation: counter-rotation 5s infinite linear;
  -webkit-transform-origin: 461.37px 173.694px;
  -ms-transform-origin: 461.37px 173.694px;
  transform-origin: 461.37px 173.694px;
}
.machine .large-shadow {
  -webkit-animation: counter-rotation 5s infinite linear;
  -moz-animation: counter-rotation 5s infinite linear;
  -o-animation: counter-rotation 5s infinite linear;
  animation: counter-rotation 5s infinite linear;
  -webkit-transform-origin: 471.37px 183.694px;
  -ms-transform-origin: 471.37px 183.694px;
  transform-origin: 471.37px 183.694px;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@-webkit-keyframes counter-rotation {
  from {
    -webkit-transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}
@-moz-keyframes counter-rotation {
  from {
    -moz-transform: rotate(359deg);
  }
  to {
    -moz-transform: rotate(0deg);
  }
}
@-o-keyframes counter-rotation {
  from {
    -o-transform: rotate(359deg);
  }
  to {
    -o-transform: rotate(0deg);
  }
}
@keyframes counter-rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e7f6f0;
  border-radius: 0.3rem;
}

.flex-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-around h1 {
  white-space: nowrap;
}

.flex-around button {
  max-width: fit-content;
}

@media (min-width: 1600px) {
  body {
    zoom: 120%;
  }
}

@media (min-width: 2000px) {
  body {
    zoom: 140%;
  }
}

.footer-bdge {
  height: 100px;
}

.modal-margin-sm .modal-content {
  max-width: 500px;
  margin: auto;
}
