.blog {
	margin-top: 8rem;
	background-color: white;
}

@media screen and (max-width: 766px) {
	.blog {
		margin-top: 12rem;
	}
}

@media screen and (max-width: 500px) {
	.blog {
		margin-top: 15rem;
	}
}

.bottom-title {
	font-size: 20px;
	font-weight: 700;
}
.extra-text {
	font-size: 12px;
	font-weight: 600;
}
.start {
	font-size: 24px;
}
.button-pev {
	width: 45px;
	height: 45px;

	border: none;
	background-color: white;
	outline: none;
	color: #696969;
	border-radius: 50%;
	position: relative;
}
.blog-slider,
.blog-feataure {
	background-color: transparent;
}
.latest-post,
.feature-slider {
	font-weight: bold;
}
.button-next {
	width: 45px;
	height: 45px;

	border: none;
	background-color: white;
	outline: none;
	border-radius: 50%;
	position: relative;
}
.button-next,
.button-pev {
	color: #2caf82;
}

.button-next[disabled],
.button-pev[disabled] {
	color: #aaa;
}
