.about-page {
	background-color: #f5f5f5;
}
.about-us-title {
	font-size: 35px;
	font-weight: 700;
}
.about-us-deatils {
	font-size: 18px;
	font-weight: 600;
	color: #36b37e;
	font-family: "Open Sans", sans-serif;
}
.about-us-header {
	margin-top: 5rem;
	margin-bottom: 8rem;
}
.our-story-bg {
	background-color: #ecf4f1;
}
.ourstory-title {
	font-size: 35px;
	font-weight: 700;
	color: #36b37e;
}
.ourstory-deatils {
	font-weight: 600;
	font-family: "Open Sans", sans-serif;
	text-align: justify;
}
.learn-more {
	padding: 12px 22px;
	width: 13rem;
	border-radius: 30px;
	border: 2px solid #2caf82;
	outline: none;
	background-color: transparent;
	color: #2caf82;
	font-size: 18px;
	font-weight: 700;
}
