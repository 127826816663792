.RichEditor-root {
  background: #fff;
  font-family: Arial, "Georgia", serif;
  font-size: 14px;
  padding: 15px;
  padding-top: 0px;
  margin-top: 10px;
}

/* .RichEditor-root * {
    margin: 0;
    padding: 0;
} */

.RichEditor-root img {
  max-width: 600px;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0px;
}

.RichEditor-editor {
  cursor: text;
  font-size: 16px;
}

.RichEditor-control-css {
  border-top: 1px solid #ddd;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: Arial, "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

pre > pre {
  margin: 0;
}

.text-center {
  text-align: center;
}

.blogs-title {
  font-weight: 100;
  font-size: 40px;
  letter-spacing: 5px;
  padding: 15px;
}

.blog-body {
  margin-bottom: 10px;
  background-color: white;
  color: black;
  border-radius: 3px;
}

.blog-title {
  font-size: 30px;
  letter-spacing: 3px;
  color: black;
  font-weight: 700;
}

a.link {
  color: #007bff !important;
  text-decoration: none !important;
  background-color: transparent !important;
  cursor: pointer !important;
}

.blog-image {
  width: 100%;
}

.blog-image img {
  width: 80%;
  max-width: 600px;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .blog-image img {
    width: 100%;
  }
}

.blog-details {
  max-height: 70vh;
  overflow-y: auto;
}
