.border-radius {
	border-radius: 50%;
	width: 400px;
	position: relative;
	height: 400px;
	background-color: #f8f7f7;
}
.circle-position {
	position: absolute;
	right: 10%;
	top: -20%;
}
.principle-title {
	font-size: 33px;
	font-weight: 600;
	color: #36b37e;
}
.principle-deatils {
	font-weight: 500;
	font-family: "Open Sans", sans-serif;
}
.innovation {
	font-size: 21px;
	font-weight: 700;
	color: #36b37e;
}
.right-deatils {
	font-weight: 600;
	font-family: "Open Sans", sans-serif;
	text-align: justify;
}
@media (max-width: 500px) {
	.border-radius {
		width: 100%;
		height: auto;
	}
}
