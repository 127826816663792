body {
  font-family: "Raleway", sans-serif;
  padding: 0px;
  margin: 0px;
}

.nav-position {
  padding: 0px;
}

.button-d0-0-3-16.button_isShown-0-3-6 {
  transform: rotateZ(-90deg) translateX(50%) translateY(0px);
  z-index: 1000;
}

.button-d0-0-3-16 {
  letter-spacing: 2px;
  color: rgb(255, 255, 255);
  right: 0px;
  border: none;
  bottom: 50%;
  cursor: pointer;
  height: 38px;
  margin: 0px;
  display: flex;
  outline: none;
  padding: 0px 14px;
  overflow: hidden;
  position: fixed;
  font-size: 16px;
  background: #36b37e;
  box-shadow: rgb(0 0 0 / 10%) -4px -12px 25px 0px;
  box-sizing: border-box;
  transition: transform 0.5s ease 0s;
  align-items: center;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-weight: 500;
  user-select: none;
  white-space: nowrap;
  border-radius: 2px 2px 0px 0px;
  justify-content: center;
  transform-origin: 100% 100%;
  appearance: none;
  border-radius: 4px;
}

.contentWrapper-0-3-12 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.loadingWave-0-3-14 {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
  position: absolute;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 68%,
      rgba(255, 255, 255, 0.3) 98%,
      rgba(0, 0, 0, 0) 100%
    )
    0% 0% / 200% 100%;
}

.text-d-none,
.text-d-none:link,
.text-d-none:focus,
.text-d-none:visited,
.text-d-none:hover,
.text-d-none:active {
  text-decoration: none;
  outline: none;
  outline: none;
  border: none;
  box-shadow: none;
  color: white !important;
}

.size-que {
  font-size: 200px;
  color: #36b37e;
  text-align: center;
}
